<template lang='pug'>
  div
    div#font
      //- the router outlet, where all matched components would ber viewed
      //div#separate
        router-link(v-bind:to="'/'")#link Home
        router-link(v-bind:to="'/about'")#link About
        router-link(v-bind:to="'/contact'")#link Contact
        router-link(v-bind:to="'/reflection'")#link Reflection
      router-view
</template>

<script>
export default {
  name: 'app',
}
</script>
<!-- styling for the component -->
<style>
body{
  margin: 0;
}
#link {
  background-color: rgb(250, 173, 186);
  padding: 10px;
  margin-left: 100px;
  text-decoration: none;
  box-shadow: 2px 2px 4px black;
}

#font {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  text-align: center;  
}

#header {
  padding: 50px;

  width: 100%;
  height: 0px;
  margin-left: -3%;
  margin-top: -2%;
}
</style>