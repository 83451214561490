// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
//import the vue instance
import Vue from 'vue'
//import the App component
import App from './App.vue'
//import the vue router
import VueRouter from 'vue-router'
//tell vue to use the router
Vue.use(VueRouter)

import VueEvaInput from 'vue-eva-input'
Vue.use(VueEvaInput)

//import the hello component
import HelloWorld from './components/HelloWorld.vue'
//import the about component
import About from './views/About/view.vue'
import Contact from './views/Contact/view.vue'
import Home from './views/Home/view.vue'
import Reflection from './views/Reflection/view.vue'


//define your routes
const routes = [
//route for the home route of the web page
{ path: '/home', component: Home },
{ path: '/helloworld', component: HelloWorld },
{ path: '/', component: Contact },
//route for the about route of the web page
{ path: '/about', component: About },
{ path: '/reflection', component: Reflection },
]

// Create the router instance and pass the `routes` option
// You can pass in additional options here, but let's
// keep it simple for now.
const router = new VueRouter({
  routes, // short for routes: routes
  mode: 'history'
})
//instatinat the vue instance
new Vue({
  render: h => h(App),
  //declare components that the root component can access
  components: { App },
  //pass in the router to the Vue instance
  router
}).$mount('#app')//mount the router on the app