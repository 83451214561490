<template>
  <div class="card">
    <form method="post" @submit.prevent="handleSubmit">
      <input type="hidden" name="form-name" value="contactForm" />
      <ul>
      <li>
        <label>
          Name:
          <input
            type="text"
            name="formName"
            @input="ev => form.formName = ev.target.value"
            >
        </label>
      </li>
      <li>
        <label>
          Email:
          <input
            type="text"
            name="formEmail"
            @input="ev => form.formEmail = ev.target.value"
          >
        </label>
      </li>
      <li>
        <label>
          <textarea
             ref="input"
             name="formMessage"
             @input="ev => form.formMessage = ev.target.value"
             placeholder="Type your Message here..."
          />
        </label>
      </li>
      </ul>
      <button type="submit" class="submit-button">Submit</button>
    </form>
  </div>
</template>
<script>
export default {
  name: "contactForm",
  data() {
    return {
      form: {
        formName: "",
        formEmail: "",
        formMessage: ""
      },
      sent: false,
      status: {}
    };
  },
  methods: {
    encode(data) {
      return Object.keys(data)
        .map(
          key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`
        )
        .join("&");
    },
    handleSubmit() {
            fetch("/", {
                method: "POST",
                headers: { "Content-Type": "application/x-www-form-urlencoded" },
                body: this.encode({
                    "form-name": "contactForm",
                    ...this.form
                })
            })
                .then(() => {
                    this.$router.push("/reflection");
                    console.log(this.form)
                })
                .catch(() => {
                    console.log(this.form)
                    this.$router.push("/about");
                });
                }
            }
}
</script>