<template lang='pug'>
  div#container
    div#home
      | This is my home page
</template>

<script>
export default {
  name: 'Home'
}
</script>
<!-- styling for the component -->
<style>
#container {
  margin-top: 50px;
}

#home {
  color: green;
  text-align: center;
  font-weight: bold;
}
</style>