<template lang='pug'>
  div#container
    div#about
      | hey
      | you can also do this
      br
      | and this
      p or this
      p.
        or you
        can do this
        multiple lines
      div
        table
          thead
            tr
              th Header 1
              th Header 2
          tbody
            tr
              td Cell 1
              td Cell 2
            tr
              td poop
              td doop
            tr
              td 
                a(href='http://www.google.com') Google
              td 
                a(href='/contact') Contact
</template>

<script>
export default {
  name: 'About'
}
</script>
<!-- styling for the component -->
<style>

table{
  border: 1px black solid;
  width: 50%;
  padding: 20px;
  margin-left: auto;
  margin-right: auto;
}

td{
  padding: 10px;
  border: 1px black solid;
}

th{
  color: blue;
  border-bottom: 1px black solid;
}
#about {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>