<template>
  <div class = "container">
    <div class="particle-container" id="particle-container">
      <div class="particle"></div>
      <div class="particle"></div>
      <div class="particle"></div>
      <div class="particle"></div>
      <div class="particle"></div>
      <div class="particle"></div>
      <div class="particle"></div>
      <div class="particle"></div>
      <div class="particle"></div>
      <div class="particle"></div>
      <div class="particle"></div>
      <div class="particle"></div>
      <div class="particle"></div>
      <div class="particle"></div>
      <div class="particle"></div>
      <div class="particle"></div>
      <div class="particle"></div>
      <div class="particle"></div>
      <div class="particle"></div>
      <div class="particle"></div>
      <div class="particle"></div>
      <div class="particle"></div>
      <div class="particle"></div>
      <div class="particle"></div>
      <div class="particle"></div>
      <div class="particle"></div>
      <div class="particle"></div>
      <div class="particle"></div>
      <div class="particle"></div>
      <div class="particle"></div>
    </div>
     <div class="home">
      <div class = "title">
        <h1>Jonathan Daw</h1>
      </div>
      <div class = "quote">
        <p>Everything is designed. Few things are designed well. <br>- Brian Reed</p>
      </div>
      <div class = "button">
        <button @click="enter" class="enterButton">Enter Portfolio</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Reflection',
  components: {

  },
  methods: {
    enter() {
      this.$router.push({ path: '/about'})
    }
  }
  
}
</script>
<!-- styling for the component -->
<style lang="scss" scoped>

  #particle-container {
    margin: 0;
    margin-bottom: 50%;
    height: 0px;
    border: 1px solid black;
  }
  .particle {
    border-radius: 50%;
  }

  @for $i from 1 through 30 {
    @keyframes particle-animation-#{$i} {
      100% {
        transform: translate3d((random(90) * 1vw), (random(90) * 1vh), (random(100) * 1px));
      }
    }
    
    .particle:nth-child(#{$i}){
      animation: particle-animation-#{$i} 60s infinite;
      $size: random(5) + 5 + px;
      opacity: random(100)/100;
      height: $size;
      width: $size;
      animation-delay: -$i * .2s;
      transform: translate3d((random(90) * 1vw), (random(90) * 1vh), (random(100) * 1px));
      background: hsl(random(360), 70%, 50%);
    }
  }

  body {
    overflow: hidden;
    margin: 0;
  }
  .container {
    overflow: hidden;
    display: flex;
    height: 100vh;
    justify-content: center;
    align-items: center;
  }

  .home {
    height: 50%;
    width: 100%;
  }

  .title{
    font-family: 'Roboto Mono', sans-serif;
  }

  .quote {
    font-family: 'Roboto Mono', sans-serif;
    line-height: 1.5em;
  }

  .button {
    margin-top: 10%;
  }

  .enterButton {
    opacity: .3;
    height: 60px;
    width: 400px;
    font-family: 'Montserrat', sans-serif;
    font-size: 30px;
  }

</style>